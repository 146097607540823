<template>
    <div class="eatDesktimeOrder defaultReport">
        <div class="content-box clearfix">
            <div class="panel-box top-info-box">
                <el-row>
                    <el-col :xs="12" :sm="8" :md="6" :lg="5" :xl="4">
                        <span class="lable-text">营业日期：{{data?.Rpt_Date}} </span>
                    </el-col>
                    <el-col :xs="12" :sm="8" :md="6" :lg="5" :xl="4">
                        <span class="lable-text">桌号评分：{{({0:"全部",1:"正常",2:"超时",3:"未完成考核"})[data?.DeskStatus]||""}} </span>
                    </el-col>
                </el-row>
                <div class="right-bnts">
                    <div class="bnt" @click="exportExcel()"><i class="iconfont icon-daochu"></i>导出报表</div>
                </div>
            </div>
            <div class="table-box grey-table isFooter" ref="tableBox">
                <el-table class="el-table--scrollable-y"  :data="tableListPage" ref="elTable"
                    border 
                    show-summary
                    :summary-method="getSummaries"
                    :row-class-name="setClassName"
                    @sort-change="sortChange" 
                    @cell-dblclick="orderDetail"
                    v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}">
                    <el-table-column fixed type="index" label="序号" width="42">
                        <template #default="scope">
                            {{scope.$index+pagesize*(currentPage-1)+1}}
                        </template>
                    </el-table-column>
                    <el-table-column fixed sortable="custom" class-name="DeskStatus" prop="DeskStatus" label="桌号评分" min-width="80">
                        <template #default="scope">
                            {{({1:"正常",2:"超时",3:"未完成"})[scope.row.DeskStatus]||""}}
                        </template>
                    </el-table-column>
                    <el-table-column fixed sortable="custom" prop="Rpt_Date" label="营业日期" min-width="80" :formatter="shortDateFormat"></el-table-column>
                    <el-table-column fixed sortable="custom" prop="Eat_RoomName" label="餐厅" min-width="80" align="left"></el-table-column>
                    <el-table-column fixed sortable="custom" prop="Eat_PhaseName" label="时段" min-width="60" align="left"></el-table-column>
                    <el-table-column fixed sortable="custom" prop="Eat_DeskName" label="桌号" min-width="70" align="left">
                        <template #default="scope">
                            <span class="link" >{{scope.row.Eat_DeskName}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column fixed sortable="custom" prop="StandardTime" label="标准时长/分" min-width="96" align="right"></el-table-column>
                    <el-table-column sortable="custom" prop="StartCookName" label="起菜计时人" min-width="96" align="left"></el-table-column>
                    <el-table-column sortable="custom" prop="BegCookTime" label="起菜时间" min-width="130" :formatter="dateFormat"></el-table-column>
                    <el-table-column sortable="custom" prop="EndCookName" label="上齐操作人" min-width="96" align="left"></el-table-column>
                    <el-table-column sortable="custom" prop="EndCookTime" label="上齐时间" min-width="130" :formatter="dateFormat"></el-table-column>
                    <el-table-column sortable="custom" prop="EndTime" label="上齐时长/分" min-width="96" data-format="number" align="right"></el-table-column>
                    <el-table-column sortable="custom" class-name="ReachStandard" prop="ReachStandard" label="达标值" min-width="70" data-format="number" align="right"></el-table-column>
                    <el-table-column sortable="custom" prop="CancelNum" label="取消上菜次数" min-width="105" data-format="number" align="right"></el-table-column>
                    <el-table-column sortable="custom" prop="Eat_DeskNum" label="桌数" min-width="70" data-format="number" align="right"></el-table-column>
                    <el-table-column sortable="custom" prop="Eat_CheckNo" label="结账单号" min-width="160" align="left"></el-table-column>
                    <el-table-column sortable="custom" prop="Channel_Name" label="销售渠道" min-width="80" align="left"></el-table-column>
                    <el-table-column sortable="custom" prop="Consume_TypeName" label="消费类型" min-width="80" align="left"></el-table-column>
                    <el-table-column sortable="custom" prop="Eat_Czy" label="开台人" min-width="80" align="left"></el-table-column>
                    <el-table-column sortable="custom" prop="Eat_BTime" label="开台时间" min-width="130" :formatter="dateFormat"></el-table-column>
                    <el-table-column sortable="custom" prop="Eat_ETime" label="结账时间" min-width="130" :formatter="dateFormat"></el-table-column>
                </el-table>
            </div>
            <table-page-btn class="fy" :data="tableFilter" 
                :default-index="currentPage" 
                @current-change="(index)=>currentPage=index"
                @page-change="(list)=>tableListPage=list" 
                :page-size="pagesize"></table-page-btn>
        </div>
        
     </div>
</template>
<script>

/**按桌号计时账单明细表 */
export default {
    name:'eatDesktimeOrder',
    props:{
        data:{
            type:Object,
            default:null
        }
    },
    data(){
        return {
            userInfo:{},
            /**表格数据 */ 
            currentPage:1,
            tableList:[],
            tableListPage:[],
            pagesize:9,//每页的数据条数
            //排序
            sortable:null,
        }
    },
    watch:{
        data:{
            handler(){
                if(this.data){
                   this.$nextTick(()=>{
                        this.GetReportData();
                   })
                }
            },
            immediate:true,
        }
    },
    computed:{
         /**筛选 或 排序 */
        tableFilter(){
            let list=Object.assign([],this.tableList);
            if(this.sortable && this.sortable.order){
                 let sorttype=this.sortable.order=='ascending'?'asc':'desc';
                list.order(this.sortable.prop,sorttype);
            } 
            return list;
        },
    },
    mounted(){
        this.userInfo = this.$auth.getUserInfo();
    },
    methods:{
        /**数据排序 */
        sortChange(column){
            this.sortable=column;
        },
        /**计算 一页显示多少条数据 */
        setTablePageSize(){
            if(this.$refs.tableBox){
                this.$nextTick(()=>{
                    let height=this.$refs.tableBox.clientHeight-35 - 50;
                    this.pagesize=parseInt(height/50);
                })
            }
        },
        /**Excel导出 */
        exportExcel(){
            if(!this.$cacheData?.globalVariable?.GlobalSysSetting.AllowUserPrintReports){
                this.$message.warning('您没有导出报表权限，请先分配权限！');
                return;
            }
            if(this.$refs.elTable){
                this.$excelCommon.elTableToExcel({
                    elTable:this.$refs.elTable,
                    titleName:"按桌号计时账单表",
                    list:this.tableFilter,
                    headArr:[
                        [
                            {value:'营业日期：'+this.data?.Rpt_Date,col:3}, 
                            {value:'桌号评分：'+(({0:"全部",1:"正常",2:"超时",3:"未完成考核"})[this.data?.DeskStatus]||""),col:3}, 
                        ],
                    ]
                })
            }
        },
        //设置列类名
        setClassName({ row }){
            if(row.DeskStatus==1){//1 正常
                return "normal";
            }else if(row.DeskStatus==2 || row.DeskStatus==3){//2超时  3未完成
                return "timeout";
            }
            return '';
        },
        /**今日 */
        nowadays(){
            this.queryType=false
            this.businessHours=[this.userInfo.Rpt_Date,this.userInfo.Rpt_Date]
        },
        change(){
            if(this.queryType==false){
                this.queryType=true
            }
        },
         /*单价格式 截取两位小数 */
        priceFormat(row, column, cellValue){
            if(isNaN(cellValue)){
                return "";
            }else{
                return parseFloat(parseFloat(cellValue).toFixed(2));
            }
        },
        /*时间格式 */
        dateFormat(row, column, cellValue) {
            if(!cellValue || cellValue=='0001-01-01T00:00:00'){
                return '';
            }
            return (new Date(cellValue)).Format('yyyy-MM-dd hh:mm:ss');
        },
        /*时间格式 */
        shortDateFormat(row, column, cellValue){
            if(!cellValue || cellValue=='0001-01-01T00:00:00'){
                return '';
            }
            return (new Date(cellValue)).Format('yyyy-MM-dd');
        },
        //合计
        getSummaries({ columns, data }){
            const sums= [];
            let total={
                Eat_DeskNum:0
            }
            this.tableList?.forEach(it=>{
                total.Eat_DeskNum+=it.Eat_DeskNum;
            });
            columns.forEach((column, index) => {
                if(total[column.property]!=undefined){
                    sums[index]=Number(total[column.property].toFixed(4));
                }else{
                    sums[index]="";
                }
            })
            sums[0]="合计";
            return sums;
        },
        /**查询 */
        GetReportData(){
            let param={
                User_ID:this.userInfo?.User_ID,
                Operator_Name:this.userInfo?.Login_Name,
                Rpt_Date:this.data?.Rpt_Date,
                DeskStatus:this.data?.DeskStatus,//桌台完成情况 1:正常 2:超时 3:未完成
                Eat_PhaseIDs:this.data?.Eat_PhaseIDs,
                Eat_RoomIDs:this.data?.Eat_RoomIDs,
                Channel_IDs:this.data?.Channel_IDs,
            }
            const loading = this.$loading({
                text: "查询中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });

            this.$httpAES.post("Bestech.CloudPos.DeskTimeBillReport",param).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.tableList=d.ResponseBody
                    this.setTablePageSize()
                    this.currentPage=1
                }else{
                    this.tableList=[];
                    this.currentPage=1
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                this.tableList=[];
                this.currentPage=1
                loading.close();
                this.$message.error(e.message);
                console.log(e);
            })
        },
        //穿透
        orderDetail(row,column){
            if(column.property=="Eat_DeskName"){//穿透 上菜顺序速度明细表
                this.$emit("addFrame",{
                    key:"eatserveDetail"+this.data?.Rpt_Date+row.Eat_AutoID,
                    data:{
                        Rpt_Date:this.data?.Rpt_Date,
                        Eat_AutoID:row.Eat_AutoID,
                        Eat_DeskName:row.Eat_DeskName,
                        StartCookName:row.StartCookName,
                        BegCookTime:row.BegCookTime,
                        EndCookName:row.EndCookName,
                        EndCookTime:row.EndCookTime,
                        StandardTime:row.StandardTime,
                    },
                    title:'按桌号计时账单明细表',
                    url:'reportEatDeskOrderDetail'
                });
            }
        }
    }
}
</script>

<style lang="scss">
@import './eatDesktimeOrder.scss'
</style>